.pointer {
  cursor: pointer;
}

button {
  outline: none !important;
}

a:hover {
  text-decoration: none;
}

.poppins {
  font-family: "Poppins", sans-serif !important
  ;
}

.scrolling-wrapper-flex {
  flex-wrap: nowrap !important;
  overflow-x: auto;
}

.scrolling-wrapper {
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
}

.flex-grow {
  flex-grow: 1;
}

.MuiTableRow-hover:hover td {
  color: #2633a9 !important;
}

.scrolling-wrapper-vertical {
  overflow-x: hidden;
  overflow-y: auto;
}

.w-fit-content {
  width: fit-content;
}

.scroll-table-y {
  display: block !important;
  position: relative !important;
  max-height: 60vh !important;
  overflow: auto !important;
}
